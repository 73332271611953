<template>
  <div
    class="
      navbar-container
      d-flex
      content
      align-items-center
      custom-navbar-container
    "
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
       v-if="$route.meta.searchBox != false"
      class="
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-none d-lg-flex
        custom-bookmark-wrapper
      "
    >
      <feather-icon icon="SearchIcon" size="21" />
      <vs-input v-model="$store.state.searchData" ref="searchInput" color="red" id="searchInput" shadow type="text" placeholder="Ctrl+f to Search"> </vs-input>
      <!-- <feather-icon style="left:8.5CM" icon="SearchIcon" size="21" /> -->
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
       <!-- <locale />
      <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!--<cart-dropdown />
      <notification-dropdown />
      <user-dropdown />       -->
      <ul class="nav navbar-nav">
        <li v-if="is_valid" class="nav-item mr-1">
          <b-button @click="$router.push({ name: 'roles' })" :class="{ 'setting-button': isActive('/roles') ||  isActive('/roles/addrole') || isActive('/roles/userrole/:id') }" class="notification-button">
            <img v-if=" isActive('/roles') ||  isActive('/roles/addrole') || isActive('/roles/userrole/:id')" src="@/assets/images/svg/settingsDark.svg" class="" />
            <img v-else src="@/assets/images/svg/settings.svg" class="" />
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
              <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
            </svg> -->
            <!-- <v-icon>mdi-settings</v-icon> -->
          </b-button>
        </li>
        <li class="nav-item">
          <b-button
            class="notification-button mr-2"
            @click="isAddNewNotificationSidebarActive = true"
          >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
          </svg> -->
            <img src="@/assets/images/svg/notifications.svg" class="" />
            <!-- <v-icon>mdi-bell</v-icon> -->
          </b-button>
          <notification-popup
            :is-add-new-notification-sidebar-active.sync="
              isAddNewNotificationSidebarActive
            "
          />
        </li>
      </ul>
      <vuesax-select :options="applications" />
      <!-- <vuesax-select v-if="$route.meta.AppDrop != false" :options="applications" /> -->
      <!-- <v-select class="mr-2" label="name_en" open="left" :options="applications"/> -->
    </b-navbar-nav>
  </div>
</template>
<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import NotificationPopup from "./components/NotificationPopup.vue";
import UserDropdown from "./components/UserDropdown.vue";
import vuesaxSelect from "../../../components/vuesax/select.vue";
import vSelect from 'vue-select';
import axios from 'axios';

export default {
  components: {
    BLink,
    vSelect,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    NotificationPopup,
    UserDropdown,
    vuesaxSelect,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      applications:[],
      search:'',
      is_valid:true,
      selectOptions: [
        { text: "Saudi Coupons", value: "1" },
        { text: "Coupon Rush", value: "2" },
        { text: "Code 71", value: "3" },
      ],
    };
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  computed:{
   app(){
    setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.is_valid = false}else{this.is_valid = true}
    }, 1000);
   }
  },
  mounted(){
    setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.is_valid = false}else{this.is_valid = true}
    }, 1000);
    if(localStorage.getItem('application')){
      this.$store.state.application = JSON.parse(localStorage.getItem('application'))
      this.applications = JSON.parse(localStorage.getItem('applications'))
      if(this.$store.state.application.id){
            this.$http.post(`${this.baseURL}/permissiongroup/get-sidebar/${this.$store.state.application.id}`).then(res => {
                this.permissions = res.data.data
                this.$store.state.sidebar = res.data.data
            })
          }
    }else{
      this.getApplications()
    }
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods:{ 
    handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault(); 
        document.getElementById('vs-input--searchInput').focus();
      }
    },
    isActive(route) {
      return this.$route.path === route;
    },
     getApplications(){
      this.$http.post(`${this.baseURL}/application/index`).then((res)=>{
            this.applications = res.data.data
            this.$store.state.application = this.applications.length > 0 ? this.applications[0] : ''
            if(this.applications.length > 0){
            localStorage.removeItem('application');
            localStorage.setItem('application',JSON.stringify(this.applications[0]));
            localStorage.removeItem('applications');
            localStorage.setItem('applications',JSON.stringify(this.applications));
            }
            if(this.$store.state.application.id){
            this.$http.post(`${this.baseURL}/permissiongroup/get-sidebar/${this.$store.state.application.id}`).then(res => {
                this.permissions = res.data.data
                this.$store.state.sidebar = res.data.data
            })
            }
          })
    }
  },
  setup() {
    const isAddNewNotificationSidebarActive = ref(false);
    return {
      isAddNewNotificationSidebarActive,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/custom/custom-navbar-vertical-layout.scss";
.navbar-container {
  .notification-button {
    border-color: transparent !important;
    background-color: #fff !important;
    color: #6e6b7b !important;
    padding: 8px !important;
    border-radius: 12px;
  }
  .setting-button {
    border-color: transparent !important;
    background-color: rgba(0, 0, 0, 0.849) !important;
    color: #fff !important;
    padding: 8px !important;
    border-radius: 12px;
  }
}
</style>
