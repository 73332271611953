<template>
  <b-sidebar
    id="add-new-notification-sidebar"
    :visible="isAddNewNotificationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-notification-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <span class="mb-0">
          Notifications
        </span>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <b-link
          v-for="notification in notifications"
          :key="notification.subtitle"
        >
          <b-media class="notify-media">
            <template #aside>
              <b-avatar
                size="40"
                :src="notification.avatar"
                :text="notification.avatar"
                :variant="notification.type"
              />
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.subtitle }}</small>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BSidebar,
    VuePerfectScrollbar
  },
  model: {
    prop: 'isAddNewNotificationSidebarActive',
    event: 'update:is-add-new-notification-sidebar-active',
  },
  props: {
    isAddNewNotificationSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  setup() {
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-notification-sidebar {
  margin: 1.3rem !important;
  border-radius: 22px;
  height: -webkit-fill-available;
  overflow: hidden;
  width: 25rem;
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
  .content-sidebar-header{
    background-color: transparent;
    border-bottom: 1px solid #E4E6E8;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;    
    font-size: 20px !important;
    color: #0A1629 !important;
    font-weight: 700;    
  }
  .notify-media{    
    border-bottom: 1px solid #E4E6E8;
    padding: 1rem;
    color:#0A1629;
  }
}
</style>
