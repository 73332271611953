
<template>
  <div class="center custom-vs-select">
    <v-select :class="$store.state.application ? 'navbarDrop1' : 'navbarDrop'" :clearable="false" :placeholder="'Select an option'" :searchable="false" :options="options" label="name_en" @input="optionSelect($event)" v-model="$store.state.application">

      <template v-slot:option="option">
        <!-- Customize the option rendering here -->
        <div>
          <img v-if="option.id == 1 || option.id == 2 || option.id == 5" src="@/assets/images/applicationSvg/SaudiCoupons.svg" class="mr-1" /> 
          <img v-else-if="option.id == 3 || option.id == 6" src="@/assets/images/applicationSvg/CouponRush.svg" class="mr-1" /> 
          <img v-else-if="option.id == 4" src="@/assets/images/applicationSvg/Code71.svg" class="mr-1" /> 
          <b-img v-else v-bind=" {width: 30,height: 30,class: 'm1'}"
                    src="https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png" alt="Circle image"
                    class="d-inline-block rounded mr-1" />
          <span>{{ option.name_en }}</span>
        </div>
      </template>

    </v-select>
    <!-- <vs-select shadow :placeholder="!value1 ? 'Select' : ''"  :value="this.$store.state.application">
      <vs-option @click="optionSelect(item)" v-for="item in options" :key="item.id" :label="item.name_en" :value="item.id">
        <img v-if="item.image_url" :src="item.image_url" class="mr-1" /> 
        <img v-else src="@/assets/images/custom/logo2.png" class="mr-1" />  
        {{ item.name_en }} </vs-option>
    </vs-select> -->
  </div>
</template>
<script>
import VSelect from 'vue-select';
import axios from 'axios';
export default {
  components:{VSelect},
  props:['options'],  
  data: () => ({
    value: "",
      baseURL: process.env.VUE_APP_BASE_URL,
    value1: "",
  }),
  methods:{
    optionSelect(e){
      if(this.$store.state.application.id){
      localStorage.removeItem('application');
      localStorage.setItem('application',JSON.stringify(this.$store.state.application));
      this.$http.post(`${this.baseURL}/permissiongroup/get-sidebar/${this.$store.state.application.id}`).then(res => {
          this.permissions = res.data.data
          this.$store.state.sidebar = res.data.data
      })
      }
      // this.$store.state.application = item
      // this.value1 = item
    }
  }
};
</script>
<style>
.navbarDrop .vs__search{
  min-width: 6cm !important;
}
.vs__search{
  padding: 0 !important;
}
.navbarDrop1 .vs__dropdown-toggle{
  min-width: 7cm !important;
}

</style>
<style lang="scss">
@import "~@core/scss/base/custom/components/custom-vs-select.scss";
</style>

        